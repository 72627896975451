import { useState } from 'react';
import { userPool } from '../login/adapters/user-pool-cognito';
import { useNavigate, useParams } from 'react-router';
import { ConfigVariables } from 'adapters/ports/config-variables';
import { useCognitoUserAttributes } from 'app/login/adapters/atributos-user-pool';
import { Link } from 'react-router-dom';

export default function NavBar() {
    const navigate = useNavigate();
    const [openNav, setOpenNav] = useState(false);
    const [openPreguntasFrecuentes, setOpenPreguntasFrecuentes] = useState(false);
    const [openNotificaciones, setOpenNotificaciones] = useState(false);
    const [openUser, setUser] = useState(false);
    const {email} = useCognitoUserAttributes();
    const { productIndex } = useParams();

    const handleHamburgerClick = (e: any) => {
        e.preventDefault();
        setOpenNav(!openNav);
    }
    const handlePreguntasFrecuentes = (e: any) => {
        e.preventDefault();
        setOpenPreguntasFrecuentes(!openPreguntasFrecuentes);
        setOpenNotificaciones(false);
        setUser(false);
    }
    
    const handleNotificaciones = (e: any) => {
        e.preventDefault();
        setOpenNotificaciones(!openNotificaciones);
        setOpenPreguntasFrecuentes(false);
        setUser(false);
    }
    
    const handleUser = (e: any) => {
        e.preventDefault();
        setUser(!openUser);
        setOpenPreguntasFrecuentes(false);
        setOpenNotificaciones(false);
    }

    const handleLogout = () => {
        const cognitoUser = userPool.getCurrentUser();
        if (cognitoUser) {

            cognitoUser.getSession((err:any, result:any)=>{
                if(result){
                cognitoUser.globalSignOut({
                    onSuccess: (result) => {
                        localStorage.clear()
                        navigate('/', { state: { message: 'Ha cerrado su sesión exitosamente!' } });
                    },
                    onFailure: (err) => {
                    console.log(err)
                    },
                });
                }
            });   
        }
    }
    
    const handlePreguntas = () => {
        if ( productIndex) {
            navigate(`/preguntas-frecuentes/${productIndex}`)
        } else {    
            navigate(`/preguntas-frecuentes`)
        }
    }

    return (
        <div>
            <div className='hidden lg:flex'>
                <nav className="absolute fixed z-50 top-0 h-[100px] max-w-full w-full border-none">
                    <div className="flex items-center justify-between ">
                        <Link to={`${productIndex ? `/carga-documentos/${productIndex}`: `/mis-creditos` }`}><img src={ConfigVariables.IMAGEN_LOGO_FINMAQ_DESKTOP} className='mt-6 ml-[42px] w-[141px]' alt='Logo' /></Link>
                        <div className="bg-white mr-5 w-[140px] h-[60px] rounded-[121px] mt-6">
                            <div className="absolute grid grid-cols-2 gap-[10px] -mt-3 ml-4">

                                <div aria-label='preguntas frecuentes' onClick={handlePreguntasFrecuentes} className={`hover:cursor-pointer mt-[26px] ${openPreguntasFrecuentes? 'bg-[#E2E8F0] rounded-3xl w-[45px] h-[45px]  mt-[20px] -ml-1 px-1.5 py-1.5 text-[#390094]' : ''}`}>
                                    <svg width={32} height={32} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.09 9C9.3251 8.33167 9.78915 7.76811 10.4 7.40913C11.0108 7.05016 11.7289 6.91894 12.4272 7.03871C13.1255 7.15849 13.7588 7.52152 14.2151 8.06353C14.6713 8.60553 14.9211 9.29152 14.92 10C14.92 12 11.92 13 11.92 13M12 17H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>
                                </div>

                                <div onClick={handleNotificaciones} className={`hover:cursor-pointer mt-[26px] ${openNotificaciones? 'bg-[#E2E8F0] rounded-3xl w-[45px] h-[45px] -ml-2 mt-[19px] px-2 py-1.5 text-[#390094] text-center hidden' : 'hidden'}`}>
                                    <svg width={32} height={32} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.391 18.015C14.8198 19.6154 13.8701 21.2604 12.2697 21.6893C10.6693 22.1181 9.02426 21.1683 8.59543 19.5679M10.8915 5.74109C11.1957 5.19379 11.2959 4.53229 11.1211 3.8797C10.7637 2.54603 9.39288 1.75457 8.05921 2.11193C6.72555 2.46928 5.93409 3.84013 6.29144 5.17379C6.46631 5.82639 6.88384 6.34916 7.42094 6.67103M16.2359 9.44553C15.879 8.1134 14.926 6.99944 13.5868 6.3487C12.2475 5.69797 10.6316 5.56377 9.09449 5.97563C7.55741 6.38749 6.22508 7.31167 5.3906 8.54486C4.55612 9.77805 4.28785 11.2192 4.64479 12.5514C5.23537 14.7554 5.12069 16.5138 4.74774 17.8499C4.32267 19.3728 4.11014 20.1342 4.16756 20.2871C4.23325 20.462 4.28076 20.5101 4.455 20.5776C4.60729 20.6366 5.24706 20.4651 6.5266 20.1223L18.3917 16.9431C19.6712 16.6002 20.311 16.4288 20.4134 16.3015C20.5305 16.1559 20.5476 16.0906 20.5171 15.9063C20.4904 15.7451 19.9256 15.192 18.796 14.0857C17.805 13.115 16.8265 11.6496 16.2359 9.44553Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>
                                </div>

                                {openUser ? (
                                    <div onClick={handleUser}>
                                        <button><img src={ConfigVariables.IMAGEN_AVATAR_LOGOUT} alt="Avatar" className="hover:cursor-pointer w-[48px] h-[48px] mt-[18px]" /></button>
                                    </div>

                                ):(
                                    <div onClick={handleUser}>
                                        <button><img src={ConfigVariables.IMAGEN_AVATAR_NAVBAR} alt="Avatar" className="hover:cursor-pointer w-[48px] h-[48px] mt-[18px]" /></button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {openPreguntasFrecuentes && (
                        <div aria-label='preguntas frecuentes' className='flex justify-end mr-[150px] mt-1 hover:cursor-pointer'>
                            <div aria-label='preguntas frecuentes' className='bg-white w-[291px] h-[48px] rounded-[6px] border border-[#E2E8F0]'>
                                <p aria-label='preguntas frecuentes' onClick={handlePreguntas} className='text-[#2D3748] justify-start p-2 text-lg'>Preguntas Frecuentes</p>
                            </div>
                        </div>
                    )}
                    {openNotificaciones && (
                        <div className='flex justify-end mr-[20px] mt-1 hover:cursor-pointer'>
                            <div className='bg-white w-[291px] h-[48px] rounded-[6px] border border-[#E2E8F0]'>
                                <p className='text-[#2D3748] justify-start p-2 text-lg'>Notificaciones</p>
                            </div>
                        </div>
                    )}
                    {openUser && (
                        <div className='flex justify-end mr-[20px] mt-1  '>
                            <div className=' w-[291px] h-[48px]  '>
                                <div  className='text-[#A0AEC0] bg-white justify-start p-2 text-lg rounded-t-[6px] border border-[#E2E8F0]'>{email}</div>
                                <div onClick={handleLogout} className='text-[#2D3748] hover:cursor-pointer justify-start p-2 text-lg bg-white rounded-b-[6px] border border-[#E2E8F0]'>Cerrar Sesión</div>
                            </div>
                        </div>
                    )}
                </nav>
            </div>
            <div className='lg:hidden flex'>
                <nav className={`absolute fixed top-0 h-[80px] w-full border-none ${openNav ? 'bg-[#ffffff]' : ''} `}>
                    <div className="flex items-center justify-between">
                    <Link to={`${productIndex ? `/carga-documentos/${productIndex}`: `/mis-creditos` }`}><img src={ConfigVariables.IMAGEN_LOGO_FINMAQ_MOBILE} className='mt-6 ml-8 w-[35px] h-[31px]' alt='Logo' /></Link>
                    <div className="bg-white mr-6 w-[40px] h-[40px] rounded-[121px] mt-6 flex items-center justify-center">
                        <button className='m-2' onClick={handleHamburgerClick}>
                        {openNav ? (
                            <div className='shadow-md bg-white w-[40px] h-[40px] rounded-[121px] flex items-center justify-center'>
                            <svg width={20} height={20} color='black' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18 6L6 18M6 6L18 18" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            </div>
                        ) : (
                            <svg width="28" height="28" color='black' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3 12H21M3 6H21M9 18H21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        )}
                        </button>
                    </div>
                    </div>
                </nav>
                {openNav && (
                    <div className="absolute min-w-full bg-white shadow-lg z-[52] flex min-h-screen flex-col justify-between">
                        <div className='ml-6 mr-6 mt-5'>
                            <p className='text-[#390094] text-xl font-bold px-3'>Menu</p>
                            <div className='border-b-2 border-[#E2E8F0]'>
                                <p className='mt-6 text-[#A0AEC0] px-3 mb-5'>{email}</p>
                            </div>
                            <div className='mt-2 text-[#2D3748] p-3 border-b-2 border-[#E2E8F0] flex'>
                                <div aria-label='preguntas frecuentes' onClick={handlePreguntas} className='flex'>
                                    <svg aria-label='preguntas frecuentes' width={20} height={20} className='hover:cursor-pointer' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.09 9C9.3251 8.33167 9.78915 7.76811 10.4 7.40913C11.0108 7.05016 11.7289 6.91894 12.4272 7.03871C13.1255 7.15849 13.7588 7.52152 14.2151 8.06353C14.6713 8.60553 14.9211 9.29152 14.92 10C14.92 12 11.92 13 11.92 13M12 17H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    <p aria-label='preguntas frecuentes' className='ml-2 mb-2'>Preguntas frecuentes</p>
                                </div>
                            </div>
                            <div className='mt-2 text-[#2D3748] p-3 flex mb-2 hidden'>
                                <svg width={20} height={20} className='hover:cursor-pointer' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.391 18.015C14.8198 19.6154 13.8701 21.2604 12.2697 21.6893C10.6693 22.1181 9.02426 21.1683 8.59543 19.5679M10.8915 5.74109C11.1957 5.19379 11.2959 4.53229 11.1211 3.8797C10.7637 2.54603 9.39288 1.75457 8.05921 2.11193C6.72555 2.46928 5.93409 3.84013 6.29144 5.17379C6.46631 5.82639 6.88384 6.34916 7.42094 6.67103M16.2359 9.44553C15.879 8.1134 14.926 6.99944 13.5868 6.3487C12.2475 5.69797 10.6316 5.56377 9.09449 5.97563C7.55741 6.38749 6.22508 7.31167 5.3906 8.54486C4.55612 9.77805 4.28785 11.2192 4.64479 12.5514C5.23537 14.7554 5.12069 16.5138 4.74774 17.8499C4.32267 19.3728 4.11014 20.1342 4.16756 20.2871C4.23325 20.462 4.28076 20.5101 4.455 20.5776C4.60729 20.6366 5.24706 20.4651 6.5266 20.1223L18.3917 16.9431C19.6712 16.6002 20.311 16.4288 20.4134 16.3015C20.5305 16.1559 20.5476 16.0906 20.5171 15.9063C20.4904 15.7451 19.9256 15.192 18.796 14.0857C17.805 13.115 16.8265 11.6496 16.2359 9.44553Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                <p className='ml-2'>Notificaciones</p>
                            </div>
                        </div>
                        <div onClick={handleLogout} className='text-[#2D3748] mb-32 p-3 m-6 border-t-2 flex items-center cursor-pointer'>
                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 8L22 12M22 12L18 16M22 12H9M15 4.20404C13.7252 3.43827 12.2452 3 10.6667 3C5.8802 3 2 7.02944 2 12C2 16.9706 5.8802 21 10.6667 21C12.2452 21 13.7252 20.5617 15 19.796" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <p className='ml-2 mb-2 mt-2'>Cerrar Sesión</p>
                        </div>
                    </div>
                )}
            </div>

        </div>
    );
}