import { FieldsProps, ObjectProps, FormFieldsProps } from "app/formulario-solicitud/utilities/formFields";

interface checkFormValuesProps {
    step:number, 
    highlightErrors:boolean, 
    formFields?:FormFieldsProps[]
}

enum CheckType {
    EMAIL,
    NAME,
    MAXLENGTH,
    MINLENGTH,
    ADDRESS,
    POSITIVENUMBER,
    CIIUCODE,
    PASTDATE
}
  
type ValidationInput = string | number;

function inputValidator(type: CheckType, input: ValidationInput, limit?: number): boolean {
    switch (type) {
        case CheckType.EMAIL:
            return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(input as string);
  
        case CheckType.NAME:
            return /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/.test(input as string);
    
        case CheckType.MAXLENGTH:
            if (typeof input !== 'number' || typeof limit !== 'number') {
                throw new Error('Invalid input for MaxLength validation');
            }
            return input <= limit;
    
        case CheckType.MINLENGTH:
            if (typeof input !== 'number' || typeof limit !== 'number') {
                throw new Error('Invalid input for MinLength validation');
            }
            return input >= limit;
    
        case CheckType.ADDRESS:
            return /^[a-zA-ZáéíóúñÁÉÍÓÚÑ0-9\s#\-.,/]+$/.test(input as string);
    
        case CheckType.POSITIVENUMBER:
            return typeof input === 'number' && input >= 0;
    
        case CheckType.CIIUCODE:
            return /^\d{4}$/.test(input as string);

        case CheckType.PASTDATE:
            const date = new Date(input);
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            return date < today;
    
        default:
            throw new Error('Invalid validation type');
    }
}

const checkFormValues = ({step, highlightErrors, formFields}: checkFormValuesProps)=>{
    let stepCompleted = true;
    const fieldsCompletedByStep:ObjectProps = {};

    const dataInputsByStep = formFields[step]?.inputs || []
    const filteredData = dataInputsByStep.filter(obj => 'name' in obj);

    filteredData.forEach(({fieldType, section, group="", name, previousLock}:FieldsProps)=>{
        if(previousLock) return;
        let currentField;
        let currentFieldValue;
        const containerField = document.querySelector(`.field-container-${section}_${group}_${name}`) as HTMLInputElement

        function showError(message: string){
            stepCompleted = false;
            if(!highlightErrors) return false

            containerField.querySelector(".error-message").textContent = message;
            containerField.querySelector(".error-message").classList.remove("hidden");            
            containerField.classList.add("error");
        }

        if(fieldType === "text" || fieldType === "email" || fieldType === "number" || fieldType === "select" || fieldType === "date" || fieldType === "phone" || fieldType === "checkbox" ) {
            currentField = document.querySelector(`.field-${section}_${group}_${name}`) as HTMLInputElement
            currentFieldValue = currentField?.value;
        }

        if(fieldType === "checkbox") currentFieldValue = currentField?.checked;

        if(fieldType === "radio"){
            currentField = document.querySelector(`.field-${section}_${group}_${name} input:checked`) as HTMLInputElement
            currentFieldValue = currentField?.value;
        }

        const valueToString = currentFieldValue.toString();

        if(name === "first_name" || name === "first_lastname" || name === "full_name"){
            if(!inputValidator(CheckType.NAME, valueToString)){
                showError("Por favor, elimine caracteres especiales.");
            }else if(!inputValidator(CheckType.MAXLENGTH, valueToString.length, 50)){
                showError("Por favor, ingrese máximo 50 caracteres.");
            }else if(!inputValidator(CheckType.MINLENGTH, valueToString.length, 2)){
                showError("Por favor, ingrese mínimo 5 caracteres.");
            }
        }

        if(name === "neighborhood" || name === "address"){
            if(!inputValidator(CheckType.ADDRESS, valueToString)){
                console.log(valueToString)
                showError("Por favor, elimine caracteres especiales.");
            }else if(!inputValidator(CheckType.MAXLENGTH, valueToString.length, 100)){
                showError("Por favor, ingrese máximo 100 caracteres.");
            }else if(!inputValidator(CheckType.MINLENGTH, valueToString.length, 2)){
                showError("Por favor, ingrese mínimo 2 caracteres.");
            }
        }
        
        if(fieldType === "email"){
            if(!inputValidator(CheckType.EMAIL, valueToString)){
                showError("Por favor, use un formato de correo correcto.");
            }
        }

        if(fieldType === "phone"){
            if(!inputValidator(CheckType.MINLENGTH, valueToString.length, 16)){
                showError("Por favor, ingrese un teléfono válido.");
            }
        }

        if(name === "sector_experience_months" || name === "months_experience"){
            if(!inputValidator(CheckType.MAXLENGTH, valueToString.length, 3)){
                showError("Por favor, ingrese máximo 3 números.");
            }else if(!inputValidator(CheckType.POSITIVENUMBER, Number(currentFieldValue))){
                showError("Por favor, ingrese un número positivo.");
            }
        }

        if(name==="ciiu_code"){
            if(!inputValidator(CheckType.CIIUCODE, valueToString)){
                showError("Por favor, ingrese un código válido de 4 dígitos.");
            }
        }

        if(fieldType === "date"){
            if(!inputValidator(CheckType.PASTDATE, valueToString)){
                showError("Por favor, escoje una fecha pasada.");
            }
        }

        if(!currentFieldValue || currentFieldValue === ""){
            showError("Por favor, complete el campo.");
        }else if(stepCompleted === true){
            const currentSection = fieldsCompletedByStep[section];

            if(currentSection){
                fieldsCompletedByStep[section] = {...currentSection, [name]: currentFieldValue}
            }else{
                fieldsCompletedByStep[section] = {[name]: currentFieldValue}
            }

            if(group){
                const currentGroup = fieldsCompletedByStep[section][group];

                if(currentGroup){
                    fieldsCompletedByStep[section][group] = {...currentGroup, [name]: currentFieldValue}
                }else{
                    fieldsCompletedByStep[section][group] = {[name]: currentFieldValue}
                }
            }

            if(containerField && containerField.classList.contains("error") && highlightErrors){
                containerField.classList.remove("error");
                containerField.querySelector(".error-message").classList.add("hidden");
            }
        }
    })

    return { stepCompleted, fieldsCompletedByStep };
}

export default checkFormValues;