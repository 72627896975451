import LayoutDocumentos from "app/carga-documentos/layouts/layout-documentos";
import FormFields, { FieldsProps, FormFieldsProps } from "app/formulario-solicitud/utilities/formFields";
import renderedInputs from "app/formulario-solicitud/utilities/renderedInputs";
import checkFormValues from "app/formulario-solicitud/utilities/checkFormValues"; 
import { useState, useEffect, useCallback } from "react";
import Stepper from "app/components-finmaq/stepper";
import classNameByEvent from "app/carga-documentos/utilities/classNameByEvent";
import uploadStatus from "app/carga-documentos/utilities/uploadStatus";
import 'react-phone-input-2/lib/style.css'
import useProductData from "app/carga-documentos/utilities/useProductData";
import { useNavigate } from 'react-router';
import { ConfigVariables } from "adapters/ports/config-variables";
import HttpClientService from "http/httpClientService";
import ModalGuardadoParcial from "app/components-finmaq/modal-guardado-parcial";
import { useParams } from 'react-router-dom';
import Modal from "app/components-finmaq/modal"; 

const FormularioSolicitud = ()=>{
    const navigate = useNavigate();

    const { productData, contactData, person_type, identity_type, identity_number, email } = useProductData();
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const [buttonStatus, setButtonStatus] = useState(classNameByEvent(uploadStatus.DISABLE));
    const [isFinalStep, setIsFinalStep] = useState(false);
    const [buttonLabel, setButtonLabel] = useState("Continuar");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [legalChecks, setLegalChecks] = useState(null);
    const [isFormDirty, setIsFormDirty] = useState(contactData ? true : false);
    const [hasSaveError, setHasSaveError] = useState(false)
    const { productIndex } = useParams();

    const apiBaseUrl = ConfigVariables.URL_BASE_API;
    const endpoint = `${apiBaseUrl}client-profile/client/${person_type}_${identity_type}_${identity_number}`;

    const handleOpenModal = useCallback(() => {
        if (isFormDirty) {
            setIsModalOpen(true);
        } else {
            navigate(`/carga-documentos/${productIndex}`);
        }
    }, [isFormDirty, navigate, productIndex]);

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const formFieldsByType = FormFields[person_type as keyof typeof FormFields];

    const selectedProductData = productData[productIndex as keyof typeof productData] || {};

    const handleChange = () => {
        const {stepCompleted} = checkFormValues({
            step: currentStepIndex, 
            highlightErrors: false, 
            formFields: formFieldsByType});

            if (!isFormDirty) {
                setIsFormDirty(true);
            }

        stepCompleted ? setButtonStatus(classNameByEvent(uploadStatus.READY)) : setButtonStatus(classNameByEvent(uploadStatus.DISABLE))
    }

    const titles = formFieldsByType?.map((steps)=>{
        return steps.title
    })

    const handleStepNavigate = (step:number)=>{
        step + 1 === formFieldsByType.length ? setButtonLabel("Guardar") : setButtonLabel("Continuar");
        setCurrentStepIndex(step);
    }

    const handlePartialSaveData = async () => {
        const { fieldsCompletedByStep} = checkFormValues({
            step: currentStepIndex,
            highlightErrors: true, 
            formFields: formFieldsByType});

            (async () => {
                try {
                    await HttpClientService.patch<string>(endpoint,fieldsCompletedByStep);
                    localStorage.setItem("failedAttempts", "");
                } catch (error){
                    console.log(error);
                    const failedAttempts = localStorage.getItem("failedAttempts") || "";
                    localStorage.setItem("failedAttempts", `${failedAttempts}f`);
                    setHasSaveError(true)

                    if(error.code === "ECONNABORTED" || localStorage.getItem("failedAttempts").length >= 3){
                        localStorage.setItem("failedAttempts", "");
                        navigate("/error-connection-timed-out");
                    }
                }
            })();
        
        if( formFieldsByType.length ) navigate(`/carga-documentos/${productIndex}`);
    }
    
    const handleSaveData = (step:number)=>{ 
        const {stepCompleted, fieldsCompletedByStep} = checkFormValues({
            step, 
            highlightErrors: true, 
            formFields: formFieldsByType});

        if(stepCompleted && formFieldsByType.length > currentStepIndex + 1) {
            step + 2 === formFieldsByType.length ? setButtonLabel("Guardar") : setButtonLabel("Continuar");

            (async () => {
                try {
                    await HttpClientService.patch<string>(endpoint,fieldsCompletedByStep);
                    localStorage.setItem("failedAttempts", "");
                    setCurrentStepIndex(a => a + 1);
                } catch (error){
                    console.log(error);
                    const failedAttempts = localStorage.getItem("failedAttempts") || "";
                    localStorage.setItem("failedAttempts", `${failedAttempts}f`);
                    setHasSaveError(true)

                    if(error.code === "ECONNABORTED" || localStorage.getItem("failedAttempts").length >= 3){
                        localStorage.setItem("failedAttempts", "");
                        navigate("/error-connection-timed-out");
                    }
                }
            })();
        }

        if(stepCompleted && formFieldsByType.length === currentStepIndex + 1) {
            const selectedProductData = productData[productIndex as keyof typeof productData] || {};
            const product_uuid = (selectedProductData as unknown as { product_uuid: string }).product_uuid;
            const endpointLegals = `${apiBaseUrl}client-profile/client/${person_type}_${identity_type}_${identity_number}/approvals`;

            const legalChecks = {
                product_uuid: product_uuid,
                approves_funds_origins: true,
                approves_terms_finmaq_go: true,
                approves_data_handling_policy_documents: true
            };

            (async () => {
                try {
                    await HttpClientService.patch<string>(endpointLegals,legalChecks);
                    localStorage.setItem("failedAttempts", "");
                } catch (error){
                    console.log(error)
                    const failedAttempts = localStorage.getItem("failedAttempts") || "";
                    localStorage.setItem("failedAttempts", `${failedAttempts}f`);
                    setHasSaveError(true)

                    if(error.code === "ECONNABORTED" || localStorage.getItem("failedAttempts").length >= 3){
                        localStorage.setItem("failedAttempts", "");
                        navigate("/error-connection-timed-out");
                    }
                }
            })();

            navigate(`/carga-documentos/${productIndex}`);
        }
    }

    const renderedForm = formFieldsByType?.map((steps:FormFieldsProps, stepsindex:number)=>{
        if(!contactData) return false;
        const renderedStep = steps.inputs.map(({label, name, fieldType, options, styleClass = "w-full lg:w-[calc(50%-20px)]", tooltip = "", subtitle = "", previousLock = false, maxLength }: FieldsProps, fieldIndex:number)=>{
            let defaultDate = "";
            let defaultValue;

            const field = steps.inputs[fieldIndex];
            const group = field["group" as keyof typeof field] || "";
            const section = field["section" as keyof typeof field] || "";

            const getSection = section ? contactData[section as keyof typeof contactData] : false;
            const getGroup = (section && group) ? getSection[group as keyof typeof getSection] : false;
            const groupValue = getGroup ? getGroup[name as keyof typeof getGroup] : "";
            const value = getSection ? getSection[name as keyof typeof getSection] : "";

            if(fieldType === "date" && name === "created_at"){
                const created_at:string = selectedProductData["created_at" as keyof typeof selectedProductData] || "";

                const dateValue = created_at.split("/");
                defaultDate = dateValue.length > 0 ? `${dateValue[2].split(",")[0]}-${dateValue[1]}-${dateValue[0]}` : "";
            }else if(fieldType === "date"){
                defaultDate = value ? value : groupValue;
            }

            if(groupValue !== ""){
                defaultValue = groupValue
            }else{
                defaultValue = value
            }

            if(email && name === "email"){
                defaultValue = email
            }

            if(name === "first_lastname"){
                const secondLastNameGroup = contactData["contact" as keyof typeof contactData];
                const secondLastNameValue = secondLastNameGroup["second_lastname" as keyof typeof secondLastNameGroup] || ""

                defaultValue += ` ${secondLastNameValue}`
            }

            return renderedInputs({label, fieldIndex, subtitle, tooltip, fieldType, options, defaultValue, previousLock, styleClass, section: section.toString(), group: group.toString(), name, defaultDate, handleChange, legalChecks, maxLength})
        })

        const stepClassName = stepsindex === 0 ? "" : "hidden";
        return <div key={`step_${stepsindex}`} className={`lg:ml-[105px] lg:mt-4 lg:mr-[105px] multiform-step multiform-step-${stepsindex} ${stepClassName} flex flex-wrap justify-between`}>
            {renderedStep}
        </div>
    })

    const handleModalError = () => {
        setHasSaveError(false);
    }

    const handleRedirectModal = () => {
        navigate(`/carga-documentos/${productIndex}`);
    }

    useEffect(()=>{
        const selectedProductData = productData[productIndex as keyof typeof productData] || {};
        const product_uuid = (selectedProductData as unknown as { product_uuid: string }).product_uuid;
        const endpointLegals = `${apiBaseUrl}client-profile/client/${person_type}_${identity_type}_${identity_number}/approvals/${product_uuid}`;

        if(person_type && product_uuid){
            (async () => {
                try {
                    const response = await HttpClientService.get<string>(endpointLegals);
                    const stringifytext = JSON.stringify(response);
                    const parsetext = JSON.parse(stringifytext);

                    setLegalChecks(parsetext.authorizations.documents_approvals);
                } catch (error){
                }
            })();
        }

        const currentContainer = document.querySelector(`.multiform-step:not(.hidden)`);
        const nextContainer = document.querySelector(`.multiform-step-${currentStepIndex}`)

        if(currentContainer) currentContainer.classList.add("hidden");
        if(nextContainer) nextContainer.classList.remove("hidden");

        formFieldsByType?.length === currentStepIndex + 1 ? setIsFinalStep(true) : setIsFinalStep(false);

        if(contactData) {
            setTimeout(()=>{
                const {stepCompleted} = checkFormValues({
                    step: currentStepIndex, 
                    highlightErrors: false, 
                    formFields: formFieldsByType});

                stepCompleted ? setButtonStatus(classNameByEvent(uploadStatus.READY)) : setButtonStatus(classNameByEvent(uploadStatus.DISABLE))
            },1)
        }

        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (isFormDirty) {
                event.preventDefault();
                event.returnValue = ""; 
                setIsModalOpen(true);
            }
        };
    
        window.addEventListener('beforeunload', handleBeforeUnload);

    },[currentStepIndex, contactData, formFieldsByType, identity_number, identity_type, person_type, productData, productIndex, isFormDirty, handleOpenModal, apiBaseUrl])

    return (
        <LayoutDocumentos 
            handleButtonClick={()=> handleSaveData(currentStepIndex)} 
            buttonLabel={buttonLabel}
            buttonClassName={buttonStatus}
            isButtonArrowInResponsive={true}
            isFinalStep={isFinalStep}
            hasFooter={true}>
            {hasSaveError &&
                <Modal
                    message={<p><strong>No hemos podido guardar sus cambios.</strong><br/>Por favor, intente de nuevo.</p>}
                    imageUrl={ConfigVariables.ERROR_AL_GUARDAR}
                    isOpen={hasSaveError}
                    primaryButtonLabel="Reintentar"
                    secondaryButtonLabel="Cancelar"
                    handleCloseModal={handleModalError}
                    handlePrimaryButton={handleModalError}
                    handleSecondaryButton={handleRedirectModal}/>
            }
            <div className="hidden lg:flex text-[#718096] ml-[105px] text-md font-normal">
                <span>Solicitud de crédito </span><span className="ml-1 text-[#000000]"> / Formulario de solicitud</span>
            </div>
            <div className="lg:mt-7 lg:ml-14 flex items-center">
                {isModalOpen && (
                    <ModalGuardadoParcial onClose={handleCloseModal} onHandleProp={handlePartialSaveData} /> 
                )}
                <button type="button" onClick={handleOpenModal} aria-label="Abrir modal">
                    <svg className="text-[#390094]" width={32} height={32} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 18L9 12L15 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>
                <p className="text-start text-[#390094] font-bold text-xl ml-2 lg:font-semibold lg:text-2xl lg:ml-[15px]">Formulario de solicitud</p>
            </div>
            <div className="text-[#171923] text-md font-normal lg:ml-[105px] lg:mr-[105px] lg:mt-2 mt-7 leading-6 lg:leading-normal w-full lg:w-auto">
                <p>Complete el siguiente formulario con la información requerida. Por favor, asegúrese de proporcionar información precisa y completa.</p>
            </div>
            <Stepper 
                titles={titles}
                currentStepIndex={currentStepIndex}
                contactData={contactData}
                handleStepNavigate={handleStepNavigate} />
            {!contactData && 
                <div className="lg:ml-[105px] lg:mt-4 lg:mr-[105px] flex flex-wrap justify-between skeleton-animation">
                    {Array.from({length: 4}).map((_, ind) =>(
                        <div key={ind} className="h-[40px] mt-[17px] mb-4 font-Poppins font-normal p-1.5 rounded-[10px] border bg-white relative field-container-text w-full lg:w-[calc(50%-20px)]">
                            <p className="skeleton-pulse"></p>
                        </div>
                    ))}
                </div>
            }
            <div>{renderedForm}</div> 
        </LayoutDocumentos>
    )
}

export default FormularioSolicitud