import { useNavigate, useParams } from "react-router-dom";
import LayoutDocumentos from "./layouts/layout-documentos";
import { useCallback, useEffect, useState } from "react";
import CardUploadFile from "app/components-finmaq/cardUploadFile";
import uploadStatus from "app/carga-documentos/utilities/uploadStatus";
import useDocumentState from "app/carga-documentos/utilities/useDocumentState";
import useGetDocuments from "app/carga-documentos/utilities/useGetDocuments";
import classNameByEvent from "app/carga-documentos/utilities/classNameByEvent";
import textsByRole from "app/carga-documentos/utilities/textsByRole";
import useProductData from "app/carga-documentos/utilities/useProductData";
import ModalGuardadoParcial from "app/components-finmaq/modal-guardado-parcial";

export default function DocumentosFiscales() {
    const { person_type } = useProductData();
    const { productIndex } = useParams();
    const { fileStatus, setFileStatus, documentUrl:documentoFiscalUrl, setDocumentUrl:setDocumentoFiscalUrl, handleInput, handleSubmit } = useDocumentState({productIndex: Number(productIndex)});
    const { documents } = useGetDocuments();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [hasChanges] = useState(false);
    const [isFileSaved, setIsFileSaved] = useState(false);
    const navigate = useNavigate();

    const isAnyFieldFilled = useCallback(() => {
        return (fileStatus === uploadStatus.READY && !isFileSaved) || hasChanges;
    }, [fileStatus, hasChanges, isFileSaved]);

    const handleOpenModal = useCallback(() => {
        if (isAnyFieldFilled()) {
            setIsModalOpen(true);
        } else {
            navigate(`/carga-documentos/${productIndex}`);
        }
    }, [isAnyFieldFilled, navigate, productIndex]);

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const commercial_product_type =
        textsByRole[person_type as keyof typeof textsByRole]?.commercial_product_type;
    const commercial_document_title =
        textsByRole[person_type as keyof typeof textsByRole]?.commercial_document_title;
    const commercial_document_description_1 =
        textsByRole[person_type as keyof typeof textsByRole]?.commercial_document_description_1;
    const commercial_document_description_2 =
        textsByRole[person_type as keyof typeof textsByRole]?.commercial_document_description_2;

    const handleButtonClick = () => {
        if (fileStatus === uploadStatus.READY) {
            handleSubmit(commercial_product_type);
            setIsFileSaved(true);
        }
    };

    useEffect(() => {
        
        const documentsByProductIndex = documents ? documents[productIndex as keyof typeof documents] : {};

        if(documentsByProductIndex && ((documentsByProductIndex.rut && documentsByProductIndex.rut.file_path) || (documentsByProductIndex.camara_comercio && documentsByProductIndex.camara_comercio.file_path))) setDocumentoFiscalUrl(documentsByProductIndex.rut?.file_path || documentsByProductIndex.camara_comercio?.file_path); 

        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (isAnyFieldFilled()) {
                event.preventDefault();
                event.returnValue = '';
                handleOpenModal();
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [documents, setDocumentoFiscalUrl, handleOpenModal, isAnyFieldFilled, productIndex]);

    return (
        <LayoutDocumentos
            handleButtonClick={handleButtonClick}
            buttonLabel="Guardar"
            buttonClassName={classNameByEvent(fileStatus)}
            hasFooter={true}>
            <div className={`${!documents ? `skeleton-animation` : ``}`}>
                <div className="hidden lg:flex text-[#718096] ml-[105px] mr-[105px] text-md font-normal">
                    <span className="skeleton-pulse">Solicitud de crédito </span>
                    <span className="ml-1 text-[#000000] skeleton-pulse"> / {commercial_document_title}</span>
                </div>
                <div className="lg:mt-7 lg:ml-14 flex items-center">
                    {isModalOpen && (
                        <ModalGuardadoParcial
                            onClose={handleCloseModal}
                            onHandleProp={handleButtonClick}
                        />
                    )}
                    <button aria-label="Abrir modal" onClick={handleOpenModal}>
                        <svg
                            className="text-[#390094]"
                            width={32}
                            height={32}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M15 18L9 12L15 6"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </button>
                    <p className="skeleton-pulse text-start text-[#390094] font-bold text-xl ml-2 lg:font-semibold lg:text-2xl lg:ml-[15px]">
                        {commercial_document_title}
                    </p>
                </div>
                <div className="text-[#171923] text-md font-normal lg:ml-[105px] lg:mr-[105px] lg:mt-2 mt-7 mb-7 lg:mb-0 leading-6 lg:leading-normal w-full lg:w-auto">
                    <p className="skeleton-pulse">{commercial_document_description_1}</p>
                </div>
                <div className="flex flex-col-reverse lg:flex-row lg:pl-[105px] lg:pr-[105px] lg:gap-11">
                    <CardUploadFile
                        key={documentoFiscalUrl}
                        fileLimit={4928307}
                        fileStatus={fileStatus}
                        fileName={documentoFiscalUrl}
                        className={"lg:w-full"}
                        description={<p className="lg:mt-[75px] skeleton-pulse">{commercial_document_description_2}</p>}
                        handleInput={handleInput}
                        setFileStatus={setFileStatus}
                    />
                </div>
            </div>
        </LayoutDocumentos>
    );
}
