import Header from "app/components-finmaq/header-terminos-y-condiciones"

export default function Autorizaciones() {
    return (
        <div className="bg-white text-justify w-full min-h-screen">
            <Header></Header>
            <hr className="lg:mt-20 mt-10 lg:ml-20 ml-6 lg:mr-20 mr-6"></hr>
            <div>
                <div className=" lg:mt-10 mt-5 lg:ml-32 ml-10 mr-10 lg:mr-32 w-auto">
                    <div className="lg:text-2xl text-lg font-bold">AUTORIZACIONES</div>
                    <div className="lg:mt-12 mt-6 text-md lg:text-lg">Declaro bajo mi responsabilidad que <b>la información que he suministrado es cierta y verificable.</b> Así mismo, autorizo en forma permanente a <b>FINANCIAMOS SU MÁQUINA S.A.S.</b>, o a quien sea en el futuro acreedor del crédito o producto solicitado para:</div>
                    <div className="lg:mt-12 mt-6 text-md lg:text-lg"><b>1.</b> Realizar las consultas y controles establecidos en los términos de sus políticas de <b>SAGRILAFT</b> y <b>PTEE</b> para verificar en las fuentes que considere necesarias, la información suministrada en el presente documento, tales como los listados de la <b>OFAC</b> (Office of Foreign Assets Control) o de las Naciones Unidas y listas vinculantes para Colombia. </div>
                    <div className="lg:mt-5  mt-6 text-md lg:text-lg"><b>2.</b> Efectuar los reportes a las autoridades competentes en materia de <b>SAGRILAFT</b> y <b>PTEE</b>, que considere necesarios realizar de conformidad con sus reglamentos y manuales de prevención y/o administración del riesgo de lavado de activos, financiamiento del terrorismo, corrupción y soborno transnacional.</div>
                    <div className="lg:mt-5  mt-6 text-md lg:text-lg"><b>3.</b> De acuerdo con lo establecido en la <b>Ley 1581 de 2012,</b> reglamentada por el <b>Decreto 1377 de 2013</b>, hacer uso de mis datos personales y en general de la información obtenida en virtud de la relación comercial establecida con <b>FINANCIAMOS SU MÁQUINA,</b> para fines operativos, comerciales, publicitarios y estadísticos, que entre otras actividades, permita la remisión de dicha información a las entidades otorgantes de créditos, subsidios y demás productos financieros. Declaro que me encuentro facultado para conocer en todo momento, la información de la cual soy titular y para solicitar la eliminación, rectificación o actualización de dicha información al correo de <b>FINANCIAMOS SU MÁQUINA</b>, de conformidad con las normas legales vigentes.</div>
                    <div className="lg:mt-5  mt-6 text-md lg:text-lg"><b>4.</b> Terminar cualquier relación en el caso de infracción de cualquiera de los numerales contenidos en el formulario y/o formato de solicitud, o cuando no actualice oportunamente los datos contenidos en el mismo por cambios en la información reportada o por solicitud de la entidad, eximiendo a la entidad mencionada anteriormente de toda responsabilidad que se derive por información errónea, falsa o inexacta que yo hubiere proporcionado. En constancia de haber leído, entendido y aceptado lo anterior, envío el formulario de solicitud y/o firmo el formato de solicitud en el espacio destinado para tal fin.</div>
                    <div className="lg:mt-5  mt-6 text-md lg:text-lg"><b>5.</b> Consultar ante las entidades de consulta de bases de datos u Operadores de información y Riesgo o cualquier entidad que maneje o administre bases de datos con los mismos fines, toda la información relevante para conocer mi desempeño como deudor, mi capacidad de pago y, en general, evaluar los riesgos derivados de la relación contractual potencial y/o vigente con <b>FINMAQ</b> y/o sus cesionarios.</div>
                    <div className="lg:mt-5  mt-6 text-md lg:text-lg"><b>6.</b> Reportar ante las entidades de consulta de bases de datos u Operadores de información y Riesgo o cualquier entidad que maneje o administre bases de datos con los mismos fines, datos relacionados con el cumplimiento oportuno o el incumplimiento en caso de presentarse, de mis obligaciones crediticias o de mis deberes legales de contenido patrimonial, de tal forma que estas presenten una información veraz, pertinente, completa, actualizada y exacta de mi desempeño como deudor, y a conservar dicha información durante el tiempo establecido en la normatividad vigente.</div>
                    <div className="lg:mt-5  mt-6 text-md lg:text-lg"><b>7.</b> Suministrar ante las entidades de consulta de bases de datos u Operadores de información y Riesgo o cualquier entidad que maneje o administre bases de datos con los mismos fines, datos relativos a mis solicitudes de crédito, así como otros atinentes a mis relaciones comerciales, financieras y en general socioeconómicas que yo haya entregado o que consten en registros públicos, bases de datos o documentos públicos.</div>
                    <div className="lg:mt-5  mt-6 text-md lg:text-lg"><b>8.</b> Para dar cumplimiento a los términos y condiciones señalados en la <b>Ley 1581 de 2012</b> y el <b>decreto 1377 de 2013</b> de protección de datos, Solicitamos su autorización para que de forma expresa <b>FINANCIAMOS SU MAQUINA S.A.S</b> utilice la información existente en su base de datos para realizarle llamadas recordatorias o enviar correspondencia escrita o electrónica de la fecha de vencimiento y estado de su crédito. <b>FINANCIAMOS SU MAQUINA S.A.S.</b> podrá monitorear y conservar todo tipo de comunicaciones que se realicen entre las partes. El cliente autoriza expresa e irrevocablemente a <b>FINANCIAMOS SU MAQUINA S.A.S.</b> para monitorear, grabar y conservar copias de todas las comunicaciones que se establezcan entre ellos incluyendo, pero sin limitarse a correos electrónicos, llamadas telefónicas y correspondencia postal. Las pruebas que puedan obtenerse por este procedimiento serán aceptadas por las partes como válidas en un procedimiento judicial.</div>
                </div><br></br><br></br><br></br><br></br>
            </div>
        </div> 
    )
}