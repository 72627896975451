import { useState, useEffect } from 'react';
import { userPool } from './user-pool-cognito';
import httpClientService from 'http/httpClientService';
import { ConfigVariables } from 'adapters/ports/config-variables';
import { useParams } from 'react-router-dom';

interface Product {
  product_uuid: string;
}

interface Client {
  products: Product[];
}

interface ApiResponse {
  client: Client;
}

export const useCognitoUserAttributes = () => {
  const [email, setEmail] = useState<string | null>(null);
  const [person_type, setPersonType] = useState<string | null>(null);
  const [identity_type, setIdentityType] = useState<string | null>(null);
  const [identity_number, setIdentityNumber] = useState<string | null>(null);
  const [product_id, setProductId] = useState<string | null>(null);

  const { productIndex } = useParams();

  useEffect(() => {
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser) {
      cognitoUser.getSession((err: any, session: any) => {
        cognitoUser.getUserAttributes((err: any, attributes: any) => {

          const emailAttribute = attributes?.find((attr: any) => attr.Name === 'email');
          setEmail(emailAttribute ? emailAttribute.Value : null);

          const userIdAttr = attributes?.find((attr: any) => attr.Name === 'custom:userID');
          const userIdValue = userIdAttr ? userIdAttr.Value : null;

          if (userIdValue && userIdValue.includes('/')) {
            const [person_type, identity_type, identity_number] = userIdValue.split('/');
            setPersonType(person_type);
            setIdentityType(identity_type);
            setIdentityNumber(identity_number);
          }
        });
      });
    }
  }, []);

  useEffect(() => {
    const fetchProductId = async () => {
      if (person_type && identity_type && identity_number) {
        const url = `${ConfigVariables.URL_BASE_API}client-profile/client/${person_type}_${identity_type}_${identity_number}`;
        
        try {
          const result = await httpClientService.get<ApiResponse>(url);

          if (result && result.client && Array.isArray(result.client.products)) {
            const productData = result.client.products
            const selectedProductData = productData[productIndex as keyof typeof productData] || {};
            const product_uuid = (selectedProductData as unknown as { product_uuid: string }).product_uuid;

            if (selectedProductData && product_uuid) {
              setProductId(product_uuid);
            } else {
              setProductId(null);
            }
          }
        } catch (error) {
      
          setProductId(null);
        }
      }
    };

    fetchProductId();
  }, [person_type, identity_type, identity_number, productIndex]);

  return { email, person_type, identity_type, identity_number, product_id };
};
