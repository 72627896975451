const ciudades = [
    {label: "Arauca", value: "Arauca"},
    {label: "Armenia", value: "Armenia"},
    {label: "Barranquilla", value: "Barranquilla"},
    {label: "Bogotá", value: "Bogotá"},
    {label: "Bucaramanga", value: "Bucaramanga"},
    {label: "Cali", value: "Cali"},
    {label: "Cartagena de Indias", value: "Cartagena de Indias"},
    {label: "Florencia", value: "Florencia"},
    {label: "Ibagué", value: "Ibagué"},
    {label: "Inírida", value: "Inírida"},
    {label: "Leticia", value: "Leticia"},
    {label: "Manizales", value: "Manizales"},
    {label: "Medellín", value: "Medellín"},
    {label: "Mitú", value: "Mitú"},
    {label: "Mocoa", value: "Mocoa"},
    {label: "Montería", value: "Montería"},
    {label: "Neiva", value: "Neiva"},
    {label: "Pereira", value: "Pereira"},
    {label: "Popayán", value: "Popayán"},
    {label: "Puerto Carreño", value: "Puerto Carreño"},
    {label: "Quibdó", value: "Quibdó"},
    {label: "Riohacha", value: "Riohacha"},
    {label: "San Andrés", value: "San Andrés"},
    {label: "San José de Cúcuta", value: "San José de Cúcuta"},
    {label: "San José del Guaviare", value: "San José del Guaviare"},
    {label: "San Juan de Pasto", value: "San Juan de Pasto"},
    {label: "Santa Marta", value: "Santa Marta"},
    {label: "Sincelejo", value: "Sincelejo"},
    {label: "Tunja", value: "Tunja"},
    {label: "Valledupar", value: "Valledupar"},
    {label: "Villavicencio", value: "Villavicencio"},
    {label: "Yopal", value: "Yopal"}
]

export default ciudades;