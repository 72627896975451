import { ConfigVariables } from "adapters/ports/config-variables";

const fileIcons = {
    pdf: ConfigVariables.ICON_PDF_FILE,
    jpg: ConfigVariables.ICON_JPG_FILE,
    jpeg: ConfigVariables.ICON_JPG_FILE,
    error: ConfigVariables.ICON_ERROR_FILE
}

export default fileIcons;

