import { ConfigVariables } from "adapters/ports/config-variables";
import httpClientService from "http/httpClientService";

class DeleteFilesService {
    private baseUrl = `${ConfigVariables.URL_BASE_API}client-profile/client`;

    public async delete(
        person_type: string,
        identity_type: string,
        identity_number: string,
        document_type: string,
        product_id: string
    ) {
        const url = `${this.baseUrl}/${person_type}_${identity_type}_${identity_number}/documents/${product_id}/${document_type}`;
        
        const resp = await httpClientService.delete(url, {})
        
        return resp;
    }
}

const deleteFilesService = new DeleteFilesService();
export default deleteFilesService;

