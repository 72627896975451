import CheckboxField from "app/components-finmaq/radioButom";
import RadioGroupField from "app/components-finmaq/radioButtomVariasPreguntas";
import { FormProps, InputTypes } from "app/components-finmaq/textFieldProps";


export default function FormularioPreguntasInfoMaquina({formulario, setFormulario}: {formulario : any, setFormulario : any}) {
    return (
        <>
            <IngresosAdicionales model={formulario} setModel={setFormulario} />
            <div className="mt-10">
                <NecesidadAdquisicion model={formulario} setModel={setFormulario} />
            </div>
        </>
    )
}


const NecesidadAdquisicion = ({model, setModel}: FormProps) => {
  
    return (
      <div>
        <RadioGroupField

          title="Escoja la opción que mejor describa la necesidad que tiene de adquirir el equipo para el cual está solicitando financiación:"
          type={InputTypes.Number}
          model={model}
          setModel={setModel}
          campoId="product.financial_product.request_reason"
          required={true}
          options={[
            { label: 'Reemplazar equipos o maquinaria que cumplió su vida útil', value: 'REPLACEMENT' },
            { label: 'Integrar un proceso que actualmente se terceriza a través de un equipo alquilado o un tercero', value: 'INTEGRATE_THIRD_PARTY_SERVICE' },
            { label: 'Nueva línea de negocio o servicio relacionada a su actividad actual', value: 'NEW_DEAL_LINE' },
            { label: (
              <>
                <div className="hidden lg:flex">
                  <div>
                    Aumento de capacidad instalada
                  </div>
                  <div className="relative group inline-block ml-1 mt-1">
                      <svg
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="cursor-pointer"
                      >
                          <path
                              d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                          />
                      </svg>
                      <div className="absolute z-50 left-1/2 bottom-full hidden w-full p-2 text-sm leading-5 text-white bg-[#171923] w-[450px] h-[72px] rounded-lg group-hover:block -translate-x-1/2">
                        Seleccione esta opción si necesita el equipo para incrementar la capacidad de producción o prestación de servicios de su negocio.
                      </div>
                  </div>
                </div>
                <div className="lg:hidden flex">
                  <div>
                    Aumento de capacidad instalada
                  </div>
                  <div className="relative group inline-block ml-2 mt-1">
                    <svg
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="cursor-pointer flex-shrink-0"
                      >
                        <path
                            d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                    <div className="absolute justify-center items-center z-50 -right-5 mb-4 bottom-full hidden p-2 text-sm leading-5 text-white bg-[#171923] w-[320px] h-[90px] rounded-lg group-hover:block ">
                      Seleccione esta opción si necesita el equipo para incrementar la capacidad de producción o prestación de servicios de su negocio.
                    </div>
                  </div>
                </div>
              </>
            ), 
            value: 'CAPACITY_INCREASE'
          },
            { label: 'Nueva actividad comercial diferente a la que desarrolla actualmente', value: 'NEW_ACTIVITY' },
          ]}
        />
      </div>
    );
};
  

const IngresosAdicionales = ({ model, setModel, editable }: FormProps) => {
    return (
        <div>
            <CheckboxField
                title='¿Posee alguna fuente de ingresos adicional a la actividad comercial para la cual requiere el equipo?'
                body="(Que represente por lo menos el 50% de sus ingresos totales)"
                type={InputTypes.Boolean}
                required={true}
                model={model}
                setModel={setModel}
                campoId="product.finance.additional_income" 
                opcionTrue='Sí'
                opcionFalse='No'
                editable={editable}
            />
        </div>
    );
};
