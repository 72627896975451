export class FormularioInfoMaquinaPreguntasDTO {
    product: {
        product_id: string
        finance: { 
            additional_income: boolean
        };
        financial_product: { 
            request_reason: string 
        };
    };

    static instancia() {
        return new FormularioInfoMaquinaPreguntasDTO();
    }
}
