import { useState } from "react"

interface ResponsiveTooltipProps {
    text: string,
    color: string,
    direction?: "left" | "right"
}

export default function ResponsiveTooltip({text, color, direction}: ResponsiveTooltipProps){
    const [isOpen, setIsOpen] = useState(false);

    const handleTooltip = (value:boolean)=>{
        setIsOpen(value)
    }

    return <div className="md:relative inline-block pointer-events-auto bg-white">
        <svg 
            className="cursor-pointer inline-block ml-2 mb-1" 
            onMouseEnter={()=> handleTooltip(true)} 
            onMouseLeave={()=> handleTooltip(false)} 
            onClick={()=> handleTooltip(!isOpen)}
            xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.99935 2.00033C4.68564 2.00033 1.99935 4.68662 1.99935 8.00033C1.99935 11.314 4.68564 14.0003 7.99935 14.0003C11.3131 14.0003 13.9993 11.314 13.9993 8.00033C13.9993 4.68662 11.3131 2.00033 7.99935 2.00033ZM0.666016 8.00033C0.666016 3.95024 3.94926 0.666992 7.99935 0.666992C12.0494 0.666992 15.3327 3.95024 15.3327 8.00033C15.3327 12.0504 12.0494 15.3337 7.99935 15.3337C3.94926 15.3337 0.666016 12.0504 0.666016 8.00033Z" fill={color}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M8.00065 7.33301C8.36884 7.33301 8.66732 7.63148 8.66732 7.99967V10.6663C8.66732 11.0345 8.36884 11.333 8.00065 11.333C7.63246 11.333 7.33398 11.0345 7.33398 10.6663V7.99967C7.33398 7.63148 7.63246 7.33301 8.00065 7.33301Z" fill={color}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M7.33398 5.33366C7.33398 4.96547 7.63246 4.66699 8.00065 4.66699H8.00732C8.37551 4.66699 8.67398 4.96547 8.67398 5.33366C8.67398 5.70185 8.37551 6.00033 8.00732 6.00033H8.00065C7.63246 6.00033 7.33398 5.70185 7.33398 5.33366Z" fill={color}/>
        </svg>

        <div className={`max-w-[90%] w-full w-[420px] md:max-w-none absolute left-1/2 -translate-x-1/2 ${direction === "left" ? "md:-translate-x-0" : "md:-translate-x-[60%]"} md:left-7 -translate-y-[150%] md:-translate-y-full bg-[#171923] text-white text-sm text-center p-1 rounded-md transition-opacity ease-in duration-200 pointer-events-none z-10 ${isOpen ? `opacity-100` : `opacity-0`}`}><span className="font-normal">{text}</span></div>
    </div>
}