export type TextFieldProps = {
    handleOnChange: (e: any) => void
    label?: string
    icon?: any
    type?: string
    value?: string | number
    disabled?: boolean
    color?: string
    name?: string
    checked?: boolean
}

export enum InputTypes {
    Text = "text",
    Number = "number",
    Email = "email",
    Date = "date",
    Money = "money",
    Percent = "percent",
    Password = "password",
    Boolean = "boolean"
}


export type FormProps = {

    model: any,
    setModel: Function,
    setOpen?: Function,
    open?: Function
    onChange?: any
    checked?: any
    editable?: boolean
}

export interface FieldProps extends FormProps {
    required?: boolean
    body?: string
    options?: any
    placeholder?: string
    campoId: string,
    label?: string,
    onBlur?: any,
    type: InputTypes,
    children?: any,
    disabled?: boolean,
    icon?: React.ReactNode,
    title?: any,
    className?: string
    max?: number
    min?: number
    opcionTrue?: string
    opcionFalse?: string
    onChange?: any
    campoIdRespuesta?: string
    setIsTyping?: any
    handleInputChange?: any
    handleBlur?: any
    countryCode?: string
    setCountryCode?: (code: string) => void
    value?: string;
}



export default FieldProps;
