import { ConfigVariables } from "adapters/ports/config-variables";
import NavBar from "app/components-finmaq/nav-bar";
import Card from "components/card";

export default function PageError404 () {
    
    return (
        <div className='w-full flex flex-col min-h-screen'>
            <form className='w-full h-full lg:w-auto lg:h-auto lg:flex flex-grow flex flex-col'>
                <div className='mt-20 mb-[10px] lg:mb-[40px] flex justify-center flex-grow'>
                    <NavBar />
                    <Card className='lg:mt-4 w-full lg:w-auto h-auto lg:h-full w-full lg:ml-5 lg:mr-5 rounded-[20px] lg:rounded-t-3xl bg-white'>
                        <div className="mt-32 lg:mt-20 flex justify-center lg:ml-[373px] lg:mr-[373px]">
                            <img alt="error 404" src={ConfigVariables.IMAGEN_ERROR_404} className="max-w-full h-auto w-[360px] lg:w-[693px] lg:h-[409px]" />
                        </div>
                        <div className="flex justify-center">
                            <p className="mt-6 text-[#2D3748] font-bold text-2xl">Página no encontrada</p>
                        </div>
                        <div className="flex justify-center">
                            <p className="mt-6 lg:mt-6 text-[#2D3748] text-md text-center font-normal leading-6 block lg:hidden">
                                Lo sentimos, la página que busca no existe. Puede que haya escrito mal la URL o que la página haya sido eliminada.
                            </p>
                            <p className="mt-6 lg:mt-6 text-[#2D3748] text-md text-center font-normal leading-6 hidden lg:block">
                                Lo sentimos, la página que busca no existe. Puede que haya escrito
                                <span className="block"> mal la URL o que la página haya sido eliminada.</span>
                            </p>
                        </div>
                        <div className="flex justify-center ">
                            <a href="/" className="mt-10 text-[#3696B2] font-normal text-md mb-20">Volver al inicio</a>
                        </div>
                    </Card>
                </div>
            </form>
        </div>
    )
}
