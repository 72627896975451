import { Button } from "@material-tailwind/react";
import Card from "components/card/";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import FormularioPreguntasInfoMaquina from "app/formulario-info-maquina/formulario/formulario-preguntas-info-maquina";
import NavBar from "app/components-finmaq/nav-bar";
import { FormularioInfoMaquinaPreguntasDTO } from "app/formulario-info-maquina/adapters/dto/formulario-info-maquina-preguntas-dto";
import formularioInfoMaquinaService from "app/formulario-info-maquina/adapters/ports/http-formulario-info-maquina";
import { useCognitoUserAttributes } from "app/login/adapters/atributos-user-pool";
import CardUploadFile from "app/components-finmaq/cardUploadFile";
import useDocumentState from "app/carga-documentos/utilities/useDocumentState";
import uploadStatus from "app/carga-documentos/utilities/uploadStatus";
import useGetDocuments from "app/carga-documentos/utilities/useGetDocuments";
import useProductData from "app/carga-documentos/utilities/useProductData";
import ModalGuardadoParcial from "app/components-finmaq/modal-guardado-parcial";
import { useParams } from 'react-router-dom';
 
export default function FormularioInfoMaquina() {
    const { person_type, identity_type, identity_number, product_id } = useCognitoUserAttributes();
    const navigate = useNavigate();
    const { productIndex } = useParams();
    const { productData } = useProductData();
 
    const [ isDataPreloaded, setIsDataPreloaded ] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modelo, setModelo] = useState<FormularioInfoMaquinaPreguntasDTO>(FormularioInfoMaquinaPreguntasDTO.instancia());
    const [isFormValid, setIsFormValid] = useState(false);
    const [hasChange] = useState(false);
    const [isFileSaved, setIsFileSaved] = useState(false);
    
 
    const { fileStatus, documentUrl:quotationUrl, setDocumentUrl:setQuotationUrl, handleInput, handleSubmit:handleSubmitDocument } = useDocumentState({productIndex: Number(productIndex)});
    
    const isAnyFieldFilled = useCallback(() => {
        
        if (isDataPreloaded) return false;
        
        const isAdditionalIncomeValid = modelo.product?.finance?.additional_income !== undefined;
        const isRequestReasonValid = modelo.product?.financial_product?.request_reason !== undefined;
        const hasFilledFields = isAdditionalIncomeValid || isRequestReasonValid || hasChange;
    
        return (
            (fileStatus === uploadStatus.READY || hasFilledFields) && !isFileSaved
        );
    }, [fileStatus, modelo, isFileSaved, hasChange, isDataPreloaded]);
    
    
 
    const handleOpenModal = useCallback(() => {
        if (isAnyFieldFilled()) {
            setIsModalOpen(true);
        } else {
            navigate(`/carga-documentos/${productIndex}`);
        }
    }, [isAnyFieldFilled, navigate, productIndex]);
    
    
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
 
    const { documents } = useGetDocuments();
 
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        handleSubmitDocument("COTIZACION", async()=>{
            try {
                await formularioInfoMaquinaService.enviar(modelo, person_type, identity_type,identity_number);
                setIsFileSaved(true);
            } catch (error) {
            }
        })
    };
    
    const handleSaveAndExit = async () => {
        try {
            if (fileStatus === uploadStatus.READY) {
                await handleSubmitDocument("COTIZACION", async () => {
                    setIsFileSaved(true);
                });
            }
        
            await formularioInfoMaquinaService.enviar(modelo, person_type, identity_type, identity_number);
            
            setIsModalOpen(false);
            navigate(`/carga-documentos/${productIndex}`);
        } catch (error) {   
        }
    };
 
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
        event.preventDefault();
        event.returnValue = '';
        if (isAnyFieldFilled()) {
            handleOpenModal();
        }
    };    
 
    window.addEventListener('beforeunload', handleBeforeUnload);
 
    useEffect(() => {
        const allFieldsFilled = modelo.product?.finance?.additional_income !== undefined && modelo.product?.financial_product?.request_reason && product_id && fileStatus === uploadStatus.READY;
        setIsFormValid(allFieldsFilled);
    
        const productDataByProductIndex = productData ? productData[productIndex as keyof typeof productData] : {};
     
        const additional_income = (productDataByProductIndex as unknown as {
            finance: {
                additional_income: boolean
            }
        }).finance?.additional_income;
    
        const request_reason = (productDataByProductIndex as unknown as {
            financial_product: {
                request_reason: string
            }
        }).financial_product?.request_reason;
    
    
        if(!isDataPreloaded && typeof additional_income !== "undefined" && request_reason){
            setIsDataPreloaded(true)
            setModelo((prevModelo) => ({
                product: {
                    product_id: product_id,
                    finance: {
                        additional_income
                    },
                    financial_product: {
                        request_reason
                    },
                },
            }));
        }
    
        if (product_id) {
            setModelo((prevModelo) => ({
                product: {
                    product_id: product_id,
                    finance: {
                        additional_income: prevModelo.product?.finance?.additional_income,
                    },
                    financial_product: {
                        request_reason: prevModelo.product?.financial_product?.request_reason,
                    },
                },
            }));
        }
        const documentsByProductIndex = documents ? documents[productIndex as keyof typeof documents] : {};
    
        if(documentsByProductIndex && documentsByProductIndex.quotation && documentsByProductIndex.quotation.file_path) setQuotationUrl(documentsByProductIndex.quotation.file_path);
    
    }, [documents, fileStatus, isDataPreloaded, modelo.product?.finance?.additional_income, modelo.product?.financial_product?.request_reason, productData, productIndex, product_id, setQuotationUrl ]);
    
 
    useEffect(() => {
        const allFieldsFilled = modelo.product?.finance?.additional_income !== undefined && modelo.product?.financial_product?.request_reason && product_id && fileStatus === uploadStatus.READY;
        setIsFormValid(allFieldsFilled);
    }, [modelo, fileStatus, product_id]);
    
 
    return (
        <div className='w-full flex flex-col min-h-screen'>
            <form className='w-full min-h-screen lg:w-auto lg:h-auto lg:flex flex-grow flex flex-col' onSubmit={handleSubmit}>
                <div className='mt-20 mb-[10px] lg:mb-[40px] flex justify-center flex-grow'>
                    <NavBar />
                    <Card className='lg:mt-4 w-full lg:w-auto min-h-screen lg:min-h-screen w-full lg:ml-5 lg:mr-5 rounded-t-[20px] lg:rounded-t-3xl bg-white'>
                        <div className="mt-7 mr-5 ml-5 lg:mt-14 lg:mr-auto lg:ml-auto">
                            <div className="mt-7 lg:ml-28 hidden lg:block">
                                <p className="flex lg:inline-block lg:text-[#718096]">Solicitud de crédito /<span className="text-start text-[#390094] lg:text-[#000000] font-bold lg:font-normal text-xl lg:text-base ml-2 lg:ml-auto"> Información de la máquina</span></p>
                            </div>
                            <div className="flex lg:ml-[65px] lg:mt-8">
                                {isModalOpen && (
                                    <ModalGuardadoParcial onClose={handleCloseModal} onHandleProp={handleSaveAndExit} />
                                )}
                                <button type="button" aria-label="volver a la pagina principal" onClick={handleOpenModal}>
                                    <svg className="text-[#390094]" width={32} height={32} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15 18L9 12L15 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </button>
                                <p className="text-start text-[#390094] font-bold lg:font-semibold text-xl lg:text-2xl ml-[8px] lg:ml-[15px]">Información de la máquina</p>
                            </div>
                            <p className="mt-6 lg:mt-1 text-md text-[#000000] lg:ml-[110px]">Responda las siguientes preguntas y suba la cotización que le envió el distribuidor de la máquina que desea financiar.</p>
                            <div className="mt-12 mb-6 lg:ml-16 lg:mt-7">
                                <div className="lg:grid lg:grid-cols-2 lg:gap-28 mt-12 lg:ml-12 lg:mr-28 mb-5">
                                    <div className="mb-16 lg:mb-0">
                                        <FormularioPreguntasInfoMaquina formulario={modelo} setFormulario={setModelo} />
                                    </div>
                                    <div>
                                        <p className="text-lg font-semibold text-[#000000]">Cotización</p>
                                        <CardUploadFile
                                            fileLimit={4928307}
                                            fileStatus={fileStatus}
                                            fileName={quotationUrl}
                                            className="w-full"
                                            handleInput={handleInput}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="lg:hidden mt-auto flex flex-col">
                            <Button
                                placeholder={""}
                                className={`w-auto mb-5 h-auto ml-10 mr-10 rounded-3xl text-md font-semibold normal-case ${isFormValid ? "bg-[#390094] text-white" : "bg-[#CBD5E0] text-[#718096]"}`}
                                type='submit'
                                disabled={!isFormValid}>
                                Guardar
                            </Button>
                        </div>
                    </Card>
                    <footer className='hidden lg:block z-50 fixed bottom-0 h-[70px] bg-gradient-to-b from-white via-[#E9EEF4] to-[#E9EEF4] w-full shadow-none border-2'>
                        <div className='text-end h-full rounded-b-[24px] bg-white ml-[18px] mr-[18px] shadow-md'>
                            <div className='mt-auto flex flex-col lg:block lg:mr-[106px]'>
                            <Button
                                className={`w-auto ${isFormValid ? "bg-[#390094] text-white" : "bg-[#CBD5E0] text-[#718096]"} mt-[8px] h-auto rounded-3xl text-md font-semibold normal-case`}
                                type='submit'
                                disabled={!isFormValid}>
                                Guardar
                            </Button>
                            </div>
                        </div>
                    </footer>
                </div>
            </form>
        </div>
    );
}