const uploadStatus = {
    DISABLE: "disable",
    READY: "ready",
    UPLOADING: "uploading",
    DONE: "done",
    WRONGFORMAT: "wrong_format",
    INVISIBLE: "invisible",
    HIDDEN: "hidden",
    ERROR: "error"
}

export default uploadStatus