import { userPool } from "app/login/adapters/user-pool-cognito";
import { HiOutlineLogout } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

export default function PagePrincipal() {

    const navigate = useNavigate();

    const handleLogout = () => {
        const cognitoUser = userPool.getCurrentUser();
        if (cognitoUser) {
            cognitoUser.signOut();
            localStorage.clear(); 
            navigate('/');
        }
    }

    return (
        <div className="flex grid grid-cols-2">
            <p>Hola Cliente, tu ingreso ha sido exitoso</p>
            <HiOutlineLogout size={32} onClick={handleLogout} color='#390094' className='hover:cursor-pointer'/>
        </div>
    )
}