import Card from "components/card/";
import NavBar from "app/components-finmaq/nav-bar";
import { Button } from "@material-tailwind/react";
import { IoArrowForward } from "react-icons/io5";

type LayoutDocumentosProps = {
    children: JSX.Element|JSX.Element[],
    buttonClassName?: string,
    buttonLabel?: string,
    isButtonArrowInResponsive?: boolean,
    isFinalStep?: boolean,
    hasFooter: boolean,
    handleButtonClick?: ()=>void
}

export default function LayoutDocumentos({children, buttonClassName, buttonLabel, isButtonArrowInResponsive, isFinalStep, hasFooter, handleButtonClick } : LayoutDocumentosProps){
    return(
        <div className="w-full flex flex-col min-h-screen">
            <div className="flex flex-grow flex-col w-full h-full lg:w-auto lg:h-auto">
                <div  className="mt-20 lg:mb-[40px] flex justify-center flex-grow">
                    <NavBar></NavBar>
                    <Card placeholder={""} className="mt-0 lg:mt-4 w-full lg:min-h-screen ml-auto mr-auto lg:ml-5 lg:mr-5 rounded-[20px] lg:rounded-t-3xl bg-white pb-14">
                        <div className="pb-32 lg:pb-0 mt-7 mr-3 ml-3 lg:mt-14 lg:mr-auto lg:ml-auto">
                            {children}
                        </div>
                    </Card>
                </div>
                {hasFooter &&
                    <footer className="block z-50 fixed bottom-0 h-[70px] lg:bg-gradient-to-b from-white via-[#E9EEF4] to-[#E9EEF4] w-full shadow-none lg:border-2">
                        <div className="text-end h-full lg:rounded-b-[24px] bg-white lg:ml-[18px] lg:mr-[18px] lg:shadow-md">
                            <div className="lg:mr-[106px] text-center lg:text-right">
                                <Button   
                                    placeholder={""}
                                    className={`w-auto mt-[8px] h-auto rounded-3xl text-md font-semibold normal-case px-24 lg:px-7 ${buttonClassName} ${isButtonArrowInResponsive && !isFinalStep ? "hidden lg:inline-block": ""}`}
                                    onClick={handleButtonClick}>
                                    {buttonLabel}
                                </Button>
                                {(isButtonArrowInResponsive && !isFinalStep) &&
                                    <button
                                        className={`rounded-[33px] h-[48px] w-[48px] absolute right-[20px] lg:hidden ${buttonClassName}`}
                                        type={'submit'}
                                        onClick={handleButtonClick}>   
                                        <div className="mr-[16px] ml-[16px]">
                                            <IoArrowForward size={18} />
                                        </div>
                                    </button>
                                }
                            </div>
                        </div>
                    </footer>
                }
            </div>
        </div>
    )
}