const paises = [
    { label: "Afgano", value: "Afgano"},
    { label: "Ålandés", value: "Ålandés"},
    { label: "Albanés", value: "Albanés"},
    { label: "Alemán", value: "Alemán"},
    { label: "Andorrano", value: "Andorrano"},
    { label: "Angoleño", value: "Angoleño"},
    { label: "Anguilense", value: "Anguilense"},
    { label: "Antártico", value: "Antártico"},
    { label: "Antiguano, Barbudense", value: "Antiguano, Barbudense"},
    { label: "Argelino", value: "Argelino"},
    { label: "Argentino", value: "Argentino"},
    { label: "Armenio", value: "Armenio"},
    { label: "Arubano", value: "Arubano"},
    { label: "Australiano", value: "Australiano"},
    { label: "Austriaco", value: "Austriaco"},
    { label: "Azerbaiyano", value: "Azerbaiyano"},
    { label: "Bahameño", value: "Bahameño"},
    { label: "Bangladesí", value: "Bangladesí"},
    { label: "Barbadense", value: "Barbadense"},
    { label: "Bareiní", value: "Bareiní"},
    { label: "Belga", value: "Belga"},
    { label: "Beliceño", value: "Beliceño"},
    { label: "Beninés", value: "Beninés"},
    { label: "Bermudeño", value: "Bermudeño"},
    { label: "Bielorruso", value: "Bielorruso"},
    { label: "Birmano", value: "Birmano"},
    { label: "Boliviano", value: "Boliviano"},
    { label: "Bosnio, Herzegovino", value: "Bosnio, Herzegovino"},
    { label: "Botsuano", value: "Botsuano"},
    { label: "Brasileño", value: "Brasileño"},
    { label: "Británico", value: "Británico"},
    { label: "Bruneano", value: "Bruneano"},
    { label: "Búlgaro", value: "Búlgaro"},
    { label: "Burkinés", value: "Burkinés"},
    { label: "Burundés", value: "Burundés"},
    { label: "Butanés", value: "Butanés"},
    { label: "Caboverdiano", value: "Caboverdiano"},
    { label: "Caimán", value: "Caimán"},
    { label: "Camboyano", value: "Camboyano"},
    { label: "Camerunés", value: "Camerunés"},
    { label: "Canadiense", value: "Canadiense"},
    { label: "Catarí", value: "Catarí"},
    { label: "Centroafricano", value: "Centroafricano"},
    { label: "Chadio", value: "Chadio"},
    { label: "Checo", value: "Checo"},
    { label: "Chileno", value: "Chileno"},
    { label: "Chino", value: "Chino"},
    { label: "Chipriota", value: "Chipriota"},
    { label: "Colombiano", value: "Colombiano"},
    { label: "Comorense", value: "Comorense"},
    { label: "Congoleño", value: "Congoleño"},
    { label: "Costarricense", value: "Costarricense"},
    { label: "Croata", value: "Croata"},
    { label: "Cubano", value: "Cubano"},
    { label: "Curazoleño", value: "Curazoleño"},
    { label: "Danés", value: "Danés"},
    { label: "De Guinea-Bisáu", value: "De Guinea-Bisáu"},
    { label: "De Hong Kong", value: "De Hong Kong"},
    { label: "De la Isla Cocos", value: "De la Isla Cocos"},
    { label: "De la Isla de Man", value: "De la Isla de Man"},
    { label: "De la Isla de Navidad", value: "De la Isla de Navidad"},
    { label: "De las Islas Cook", value: "De las Islas Cook"},
    { label: "De las Islas Heard y McDonald", value: "De las Islas Heard y McDonald"},
    { label: "De las Islas Turcas y Caicos", value: "De las Islas Turcas y Caicos"},
    { label: "De las Islas Vírgenes", value: "De las Islas Vírgenes"},
    { label: "De Liechtenstein", value: "De Liechtenstein"},
    { label: "De Montserrat", value: "De Montserrat"},
    { label: "De San Bartolomé", value: "De San Bartolomé"},
    { label: "De San Martín", value: "De San Martín"},
    { label: "De San Pedro y Miquelón", value: "De San Pedro y Miquelón"},
    { label: "De Seychelles", value: "De Seychelles"},
    { label: "De Sint Maarten", value: "De Sint Maarten"},
    { label: "De Sri Lanka", value: "De Sri Lanka"},
    { label: "De Trinidad y Tobago", value: "De Trinidad y Tobago"},
    { label: "De Wallis y Futuna", value: "De Wallis y Futuna"},
    { label: "Dominicano", value: "Dominicano"},
    { label: "Ecuatoguineano", value: "Ecuatoguineano"},
    { label: "Ecuatoriano", value: "Ecuatoriano"},
    { label: "Egipcio", value: "Egipcio"},
    { label: "Emiratí", value: "Emiratí"},
    { label: "Eritreo", value: "Eritreo"},
    { label: "Eslovaco", value: "Eslovaco"},
    { label: "Esloveno", value: "Esloveno"},
    { label: "Español", value: "Español"},
    { label: "Estadounidense", value: "Estadounidense"},
    { label: "Estadounidense Insular", value: "Estadounidense Insular"},
    { label: "Estonio", value: "Estonio"},
    { label: "Etíope", value: "Etíope"},
    { label: "Feroés", value: "Feroés"},
    { label: "Filipino", value: "Filipino"},
    { label: "Finlandés", value: "Finlandés"},
    { label: "Fiyiano", value: "Fiyiano"},
    { label: "Francés", value: "Francés"},
    { label: "Gabonés", value: "Gabonés"},
    { label: "Gambiano", value: "Gambiano"},
    { label: "Georgiano", value: "Georgiano"},
    { label: "Georgiano del Sur, Sandwichense del Sur", value: "Georgiano del Sur, Sandwichense del Sur"},
    { label: "Ghanés", value: "Ghanés"},
    { label: "Gibraltareño", value: "Gibraltareño"},
    { label: "Granadino", value: "Granadino"},
    { label: "Griego", value: "Griego"},
    { label: "Groenlandés", value: "Groenlandés"},
    { label: "Guadalupeño", value: "Guadalupeño"},
    { label: "Guamense", value: "Guamense"},
    { label: "Guatemalteco", value: "Guatemalteco"},
    { label: "Guerneseyeso", value: "Guerneseyeso"},
    { label: "Guineano", value: "Guineano"},
    { label: "Guyanés", value: "Guyanés"},
    { label: "Guyanés (Francés)", value: "Guyanés (Francés)"},
    { label: "Haitiano", value: "Haitiano"},
    { label: "Hondureño", value: "Hondureño"},
    { label: "Húngaro", value: "Húngaro"},
    { label: "I-Kiribati", value: "I-Kiribati"},
    { label: "Indio", value: "Indio"},
    { label: "Indonesio", value: "Indonesio"},
    { label: "Iraní", value: "Iraní"},
    { label: "Iraquí", value: "Iraquí"},
    { label: "Irlandés", value: "Irlandés"},
    { label: "Islandés", value: "Islandés"},
    { label: "Israelí", value: "Israelí"},
    { label: "Italiano", value: "Italiano"},
    { label: "Jamaicano", value: "Jamaicano"},
    { label: "Japonés", value: "Japonés"},
    { label: "Jersiano", value: "Jersiano"},
    { label: "Jordano", value: "Jordano"},
    { label: "Kazajo", value: "Kazajo"},
    { label: "Keniano", value: "Keniano"},
    { label: "Kirguís", value: "Kirguís"},
    { label: "Kosovaro", value: "Kosovaro"},
    { label: "Kuwaití", value: "Kuwaití"},
    { label: "Laosiano", value: "Laosiano"},
    { label: "Lesotense", value: "Lesotense"},
    { label: "Letón", value: "Letón"},
    { label: "Libanés", value: "Libanés"},
    { label: "Liberiano", value: "Liberiano"},
    { label: "Libio", value: "Libio"},
    { label: "Lituano", value: "Lituano"},
    { label: "Luxemburgués", value: "Luxemburgués"},
    { label: "Macaense", value: "Macaense"},
    { label: "Macedonio", value: "Macedonio"},
    { label: "Mahorense", value: "Mahorense"},
    { label: "Malasio", value: "Malasio"},
    { label: "Malauí", value: "Malauí"},
    { label: "Maldivo", value: "Maldivo"},
    { label: "Malgache", value: "Malgache"},
    { label: "Maliense", value: "Maliense"},
    { label: "Maltés", value: "Maltés"},
    { label: "Malvinense", value: "Malvinense"},
    { label: "Marfileño (Ivoriano)", value: "Marfileño (Ivoriano)"},
    { label: "Marroquí", value: "Marroquí"},
    { label: "Marshallés", value: "Marshallés"},
    { label: "Martiniqueño", value: "Martiniqueño"},
    { label: "Mauriciano", value: "Mauriciano"},
    { label: "Mauritano", value: "Mauritano"},
    { label: "Mexicano", value: "Mexicano"},
    { label: "Micronesio", value: "Micronesio"},
    { label: "Moldavo", value: "Moldavo"},
    { label: "Monegasco", value: "Monegasco"},
    { label: "Mongol", value: "Mongol"},
    { label: "Montenegrino", value: "Montenegrino"},
    { label: "Mozambiqueño", value: "Mozambiqueño"},
    { label: "Namibio", value: "Namibio"},
    { label: "Nauruano", value: "Nauruano"},
    { label: "Neerlandés (Holandés)", value: "Neerlandés (Holandés)"},
    { label: "Neocaledonio", value: "Neocaledonio"},
    { label: "Neozelandés", value: "Neozelandés"},
    { label: "Nepalí", value: "Nepalí"},
    { label: "Nicaragüense", value: "Nicaragüense"},
    { label: "Nigeriano", value: "Nigeriano"},
    { label: "Nigerino", value: "Nigerino"},
    { label: "Niueano", value: "Niueano"},
    { label: "Norcoreano", value: "Norcoreano"},
    { label: "Norfolqueso", value: "Norfolqueso"},
    { label: "Noruego", value: "Noruego"},
    { label: "Omaní", value: "Omaní"},
    { label: "Pakistaní", value: "Pakistaní"},
    { label: "Palauano", value: "Palauano"},
    { label: "Palestino", value: "Palestino"},
    { label: "Panameño", value: "Panameño"},
    { label: "Papuano", value: "Papuano"},
    { label: "Paraguayo", value: "Paraguayo"},
    { label: "Peruano", value: "Peruano"},
    { label: "Pitcairneso", value: "Pitcairneso"},
    { label: "Polaco", value: "Polaco"},
    { label: "Polinesio Francés", value: "Polinesio Francés"},
    { label: "Portugués", value: "Portugués"},
    { label: "Puertorriqueño", value: "Puertorriqueño"},
    { label: "Reunionesa", value: "Reunionesa"},
    { label: "Ruandés", value: "Ruandés"},
    { label: "Rumano", value: "Rumano"},
    { label: "Ruso", value: "Ruso"},
    { label: "Saharaui", value: "Saharaui"},
    { label: "Salomonense", value: "Salomonense"},
    { label: "Salvadoreño", value: "Salvadoreño"},
    { label: "Samoano", value: "Samoano"},
    { label: "Samoano Estadounidense", value: "Samoano Estadounidense"},
    { label: "Sancristobaleño o Nevisense", value: "Sancristobaleño o Nevisense"},
    { label: "Sanmarinense", value: "Sanmarinense"},
    { label: "Santahelenense", value: "Santahelenense"},
    { label: "Santalucense", value: "Santalucense"},
    { label: "Santotomense", value: "Santotomense"},
    { label: "Sanvicentino", value: "Sanvicentino"},
    { label: "Saudita", value: "Saudita"},
    { label: "Senegales", value: "Senegales"},
    { label: "Serbio", value: "Serbio"},
    { label: "Sierraleonés", value: "Sierraleonés"},
    { label: "Singapurense", value: "Singapurense"},
    { label: "Sirio", value: "Sirio"},
    { label: "Somalí", value: "Somalí"},
    { label: "Suazi", value: "Suazi"},
    { label: "Sudafricano", value: "Sudafricano"},
    { label: "Sudanés", value: "Sudanés"},
    { label: "Sudanés del Sur", value: "Sudanés del Sur"},
    { label: "Sueco", value: "Sueco"},
    { label: "Suizo", value: "Suizo"},
    { label: "Surcoreano", value: "Surcoreano"},
    { label: "Surinamés", value: "Surinamés"},
    { label: "Tailandés", value: "Tailandés"},
    { label: "Taiwanés", value: "Taiwanés"},
    { label: "Tanzano", value: "Tanzano"},
    { label: "Tayiko", value: "Tayiko"},
    { label: "Timorense", value: "Timorense"},
    { label: "Togolés", value: "Togolés"},
    { label: "Tokelauano", value: "Tokelauano"},
    { label: "Tongano", value: "Tongano"},
    { label: "Tunecino", value: "Tunecino"},
    { label: "Turco", value: "Turco"},
    { label: "Turcomano", value: "Turcomano"},
    { label: "Tuvaluano", value: "Tuvaluano"},
    { label: "Ucraniano", value: "Ucraniano"},
    { label: "Ugandés", value: "Ugandés"},
    { label: "Uruguayo", value: "Uruguayo"},
    { label: "Uzbeko", value: "Uzbeko"},
    { label: "Vanuatuense", value: "Vanuatuense"},
    { label: "Vaticano", value: "Vaticano"},
    { label: "Venezolano", value: "Venezolano"},
    { label: "Vietnamita", value: "Vietnamita"},
    { label: "Yemení", value: "Yemení"},
    { label: "Yibutiano", value: "Yibutiano"},
    { label: "Zambiano", value: "Zambiano"},
    { label: "Zimbabuense", value: "Zimbabuense"}
]
export default paises;
