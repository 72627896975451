import { useState, useEffect } from "react";
import { userPool } from "app/login/adapters/user-pool-cognito";
import HttpClientService from "http/httpClientService";
import { ConfigVariables } from "adapters/ports/config-variables";
import { useNavigate } from "react-router-dom";
 
const useGetDocuments = ()=>{
    const [documents, setDocuments] = useState(null);

    const [person_type, setPersonType] = useState<string | null>(null);
    const [identity_type, setIdentityType] = useState<string | null>(null);
    const [identity_number, setIdentityNumber] = useState<string | null>(null);

    const navigate = useNavigate();

    useEffect(()=>{
        const cognitoUser = userPool.getCurrentUser();
        
        if (cognitoUser && (!person_type || !identity_type || !identity_number)) {
            cognitoUser.getSession((err: any, session: any) => {
                if (err) {
                    console.error("Error getting session:", err);
                    return;
                }

                cognitoUser.getUserAttributes((err: any, attributes: any) => {
                    if (err) {
                        console.error("Error getting user attributes:", err);
                        return;
                    }

                    const userID = attributes.find((attr: any) => attr.Name === 'custom:userID').Value.split("/");
                    const [person_type, identity_type, identity_number] = userID;

                    setPersonType(person_type);
                    setIdentityType(identity_type);
                    setIdentityNumber(identity_number);
                });
            });
        }
        
        if(!documents && person_type && identity_type && identity_number){
            (async () => {
                try {
                    const apiBaseUrl = ConfigVariables.URL_BASE_API;

                    const getUserEndpoint = `${apiBaseUrl}client-profile/client/${person_type}_${identity_type}_${identity_number}/documents`;
                    const response = await HttpClientService.get<string>(getUserEndpoint);
                    const stringifytext = JSON.stringify(response);
                    const parsetext = JSON.parse(stringifytext);

                    setDocuments(parsetext.documents)
                    
                } catch (error) {
                    console.error('Error al obtener los documentos del usuario:', error);
                    navigate("/error-connection-timed-out");
                    throw error;
                }
            })();
        }
    },[documents, person_type, identity_type, identity_number, navigate])

    return {documents};
}

export default useGetDocuments;
