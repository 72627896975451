import React from 'react';
import FieldProps from './textFieldProps';

interface Option {
  label: string | React.ReactElement;
  value: string | number;
}

interface RadioButtonGroupProps extends FieldProps {
  options: Option[];
}

const RadioButtonGroup = ({ model, setModel, campoId, title, required, options, onChange = () => {} }: RadioButtonGroupProps) => {

  const getNestedValue = (obj: any, fieldId: string) => {
    const keys = fieldId.split('.');
    return keys.reduce((acc, key) => acc && acc[key], obj);
  };

  const handleChange = (value: string | number) => {
    setModel((prevModel: any) => {
      const keys = campoId.split('.');
      let newModel = { ...prevModel };
      let temp = newModel;

      keys.slice(0, -1).forEach(key => {
        if (!temp[key]) temp[key] = {};
        temp = temp[key];
      });

      temp[keys[keys.length - 1]] = value;

      return newModel;
    });
    onChange(value);
  };

  return (
    <>
      <div className='hidden lg:flex w-auto'>
        <div>
          <div className='items-center w-auto'>
            <div className="text-md lg:text-sm text-[#171923] font-semibold leading-5">{title}</div>
          </div>
          <div className='mt-5 text-md mb-12 flex flex-col'>
            {options.map((option) => (
              <label key={option.value} htmlFor={`radio-${campoId}-${option.value}`} className="flex items-center mr-5 mt-5 cursor-pointer">
                <input
                  className="peer sr-only"
                  type="radio"
                  id={`radio-${campoId}-${option.value}`}
                  name={`radio-group-${campoId}`}
                  required={required}
                  checked={getNestedValue(model, campoId) === option.value}
                  onChange={() => handleChange(option.value)}
                />
                <div className='w-[20px] h-[20px] flex-shrink-0 border border-gray-300 rounded-full peer-checked:border-[6px] peer-checked:border-[#390094]'></div>
                <span className="ml-2">{option.label}</span>
              </label>
            ))}
          </div>
        </div>
      </div>
      <div className='lg:hidden w-auto'>
        <div className='flex items-center w-auto'>
          <div className='text-md text-[#171923] font-semibold'>{title}</div>
        </div>
        <div className='mt-5 text-md flex flex-wrap'>
          {options.map((option) => (
            <label key={option.value} htmlFor={`${campoId}-${option.value}`} className="flex items-center mr-5 mt-5 cursor-pointer">
              <input
                className='peer sr-only'
                type="radio"
                id={`${campoId}-${option.value}`}
                name={campoId}
                required={required}
                checked={getNestedValue(model, campoId) === option.value}
                onChange={() => handleChange(option.value)}
              />
              <div className='w-[20px] h-[20px] flex-shrink-0 border border-gray-300 rounded-full peer-checked:border-[6px] peer-checked:border-[#390094]'></div>
              <span className="ml-2">{option.label}</span>
            </label>
          ))}
        </div>
      </div>
    </>
  );
};

export default RadioButtonGroup;
