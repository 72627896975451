import { Button } from "@material-tailwind/react";
import { ConfigVariables } from "adapters/ports/config-variables";
import { createPortal } from "react-dom";
import { IoCloseSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';

interface ModalGuradoParcialProps {
    onClose : () => void;
    onHandleProp? : () => void;
}

export default function ModalGuardadoParcial({ onClose, onHandleProp } : ModalGuradoParcialProps) {
    const { productIndex } = useParams();
    
    const navigate = useNavigate();

    const handleSalirSinGuardar = () => {
        
        navigate(`/carga-documentos/${productIndex}`);
    }

    return (
        createPortal(
            <div className="fixed top-0 left-0 w-full h-full z-50">
                <div className="absolute inset-0 bg-[#000000] opacity-50"></div>
                <div className="fixed inset-0 flex items-center justify-center z-[101]">
                    <div className="bg-white rounded-[25px] shadow-lg lg:w-[448px] lg:h-[420px] w-[350px] h-[420px]">
                        <div className="flex mt-[15px] lg:ml-[410px] h-[60px] ml-[315px]">
                            <IoCloseSharp className='hover:cursor-pointer w-[22px] h-[22px]' onClick={onClose} />
                        </div>
                        <div className="flex justify-center lg:mt-4 mt-2">
                            <img src={ConfigVariables.IMAGEN_MODAL_GUARDADO_PARCIAL} alt="imagen guardado parcial" className="h-[180px] w-[179px]" />
                        </div>
                        <div className="flex justify-center">
                            <p className="text-md font-normal leading-6">
                                Tiene cambios sin guardar.<br />
                                <span className="font-semibold text-md leading-6">
                                    ¿Quiere salir sin guardar?
                                </span>
                            </p>
                        </div>
                        <div className="flex grid grid-cols-2 gap-3 mt-10 mb-4 h-[40px] w-auto">
                            <Button onClick={handleSalirSinGuardar} aria-label="salir sin guardar" className="bg-[#EDF2F7] text-[#1A202C] -px-4 text-md font-semibold rounded-[28px] ml-6 shadow-none normal-case">
                                Salir sin guardar
                            </Button>
                            <Button onClick={onHandleProp} aria-label="guardar y salir" className="bg-[#390094] text-white -px-4 text-md font-semibold rounded-[28px] mr-6 shadow-none normal-case">
                                Guardar y salir
                            </Button>
                        </div>
                    </div>
                </div>
            </div>, 
        document.body)
    );
}
