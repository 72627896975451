import httpClientService from "http/httpClientService";
import { FormularioInfoMaquinaPreguntasDTO } from "../dto/formulario-info-maquina-preguntas-dto";
import { ConfigVariables } from "adapters/ports/config-variables";

class FormularioInfoMaquinaService {
    private baseUrl = `${ConfigVariables.URL_BASE_API}client-profile/client`;

    public async enviar(
        formularioInfoMaquinaNuevo: FormularioInfoMaquinaPreguntasDTO,
        person_type: string,
        identity_type: string,
        identity_number: string
    ) {
        const url = `${this.baseUrl}/${person_type}_${identity_type}_${identity_number}`;
        
        const resp = await httpClientService.patch(url, formularioInfoMaquinaNuevo);
        return resp;
    }
}

const formularioInfoMaquinaNuevo = new FormularioInfoMaquinaService();
export default formularioInfoMaquinaNuevo;
