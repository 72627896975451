import React, { useState } from 'react';
import {
    Accordion,
    AccordionHeader,
    AccordionBody,
} from "@material-tailwind/react";

interface AccordionComponentProps {
    id?: string;
    body: React.ReactNode; 
    title: React.ReactNode;
}

const AccordionComponent: React.FC<AccordionComponentProps> = ({id, body, title }) => {
    const [open, setOpen] = useState(false);

    const toggleAccordion = () => {
        setOpen(!open);
    };

    return (
        <>  
            <Accordion 
                open={open} 
                placeholder={"acordeon"} 
                onChange={toggleAccordion} 
                className="border-b border-[#EAE5F3]" 
            >
                <AccordionHeader 
                    placeholder={"acordeonHeader"} 
                    onClick={toggleAccordion} 
                    className='text-[#000000] text-md font-semibold border-none'
                >
                    <div className='flex items-center justify-between w-full'>
                        <div className='flex-1 ml-4'>
                            {title}
                        </div>
                        <div className='flex '>
                        {open ? (
                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18 15L12 9L6 15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            ) : (
                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 9L12 15L18 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            )}
                        </div>
                    </div>
                </AccordionHeader>
                <AccordionBody className="text-[#000000] text-base text-sm leading-6 px-4 mb-[40px]"> 
                    {body}
                </AccordionBody>
            </Accordion>
        </>
    );
}

export default AccordionComponent;

