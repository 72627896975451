const classNameByEvent = (state:string)=>{
    switch(state){
        case "ready":
            return "bg-[#390094] text-white";
        case "disable":
        case "done":
        case "incorrectformat":
        case "uploading":
        case "error":
            return "bg-[#CBD5E0] text-[#718096]";
        case "invisible":
            return "invisible";
        case "hidden":
            return "hidden bg-[#CBD5E0] text-[#718096]";
        default:
            return "";
    }
}

export default classNameByEvent