
export const ConfigVariables = {
    COGNITO_USER_POOL_ID : process.env.REACT_APP_COGNITO_USER_POOL_ID,
    COGNITO_CLIENT : process.env.REACT_APP_COGNITO_CLIENT,
    IMAGEN_COMPUTADOR_ONBOARDING : `${process.env.REACT_APP_FINMAQ_GO_FILES_URL}/ilustrados_computador.svg`,
    IMAGEN_CELULAR_ONBOARDING : `${process.env.REACT_APP_FINMAQ_GO_FILES_URL}/Ilustrados_celular.svg`,
    IMAGEN_CAFE_ONBOARDING : `${process.env.REACT_APP_FINMAQ_GO_FILES_URL}/ilustrados_cafe.svg`,
    IMAGEN_MONEDAS_ONBOARDING : `${process.env.REACT_APP_FINMAQ_GO_FILES_URL}/ilustrados_monedas.svg`,
    IMAGEN_WALLET_ONBOARDING : `${process.env.REACT_APP_FINMAQ_GO_FILES_URL}/ilustrados_wallet.svg`,
    IMAGEN_PRINCIPAL_ONBOARDING_DESKTOP : `${process.env.REACT_APP_FINMAQ_GO_FILES_URL}/imagen_onboard_desktop.svg`,
    IMAGEN_PRINCIPAL_ONBOARDING_MOBILE : `${process.env.REACT_APP_FINMAQ_GO_FILES_URL}/onboarding-image.jpg`,
    IMAGEN_LOGO_FINMAQ_DESKTOP : `${process.env.REACT_APP_FINMAQ_GO_FILES_URL}/logo-finmaq.svg`,
    IMAGEN_LOGO_FINMAQ_MOBILE : `${process.env.REACT_APP_FINMAQ_GO_FILES_URL}/logo-finmaq-mobile-v2.svg`,
    IMAGEN_AVATAR_NAVBAR : `${process.env.REACT_APP_FINMAQ_GO_FILES_URL}/Avatar.svg`,
    IMAGEN_AVATAR_LOGOUT : `${process.env.REACT_APP_FINMAQ_GO_FILES_URL}/Avatar_logout.svg`,
    IMAGEN_LOGIN_DESKTOP : `${process.env.REACT_APP_FINMAQ_GO_FILES_URL}/imagen_login_desktop.svg`,
    IMAGEN_LOGIN_MOBILE : `${process.env.REACT_APP_FINMAQ_GO_FILES_URL}/prueba.png`,
    IMAGEN_LOGIN_MOBILE_BANNER_ESCRITURA : `${process.env.REACT_APP_FINMAQ_GO_FILES_URL}/banner_escritura_login.svg`,
    ICON_CHECK_SUCCESS : `${process.env.REACT_APP_FINMAQ_GO_FILES_URL}/icon_check_success.svg`,
    ICON_ALERT_ERROR : `${process.env.REACT_APP_FINMAQ_GO_FILES_URL}/icon_alert_error.svg`,
    ICON_ALERT_FAQS : `${process.env.REACT_APP_FINMAQ_GO_FILES_URL}/alter_icon_faqs.svg`,
    IMAGEN_FAQ: `${process.env.REACT_APP_FINMAQ_GO_FILES_URL}/Ilustracion_FAQ.svg`,
    ICON_PDF_FILE : `${process.env.REACT_APP_FINMAQ_GO_FILES_URL}/icon_pdf.svg`,
    ICON_JPG_FILE : `${process.env.REACT_APP_FINMAQ_GO_FILES_URL}/icon_jpg.svg`,
    ICON_ERROR_FILE : `${process.env.REACT_APP_FINMAQ_GO_FILES_URL}/icon_file_error.svg`,
    IMAGEN_RECOMIENDACION_COMPLETA : `${process.env.REACT_APP_FINMAQ_GO_FILES_URL}/recomendacion_imagen_completa.svg`,
    IMAGEN_CEDULA_ADELANTE : `${process.env.REACT_APP_FINMAQ_GO_FILES_URL}/cedula-adelante.svg`,
    IMAGEN_CEDULA_ATRAS : `${process.env.REACT_APP_FINMAQ_GO_FILES_URL}/cedula-atras.svg`,
    IMAGEN_CAMBIAR_PASSWORD : `${process.env.REACT_APP_FINMAQ_GO_FILES_URL}/Recuperar-password.svg`,
    IMAGEN_SESION_EXPIRADA : `${process.env.REACT_APP_FINMAQ_GO_FILES_URL}/sesion-expirada.svg`,
    IMAGEN_ERROR_404 : `${process.env.REACT_APP_FINMAQ_GO_FILES_URL}/error_404.svg`,
    IMAGEN_ERROR_500 : `${process.env.REACT_APP_FINMAQ_GO_FILES_URL}/error_500.svg`,
    IMAGEN_MODAL_ELIMINAR_ARCHIVOS : `${process.env.REACT_APP_FINMAQ_GO_FILES_URL}/eliminar_archivo.svg`,
    IMAGEN_MODAL_GUARDADO_PARCIAL : `${process.env.REACT_APP_FINMAQ_GO_FILES_URL}/modal-guardado-parcial.svg`,
    ALERTA_CREDITOS_MAYORES : `${process.env.REACT_APP_FINMAQ_GO_FILES_URL}/alerta_credito_mayores.svg`,
    ALERT_ICON : `${process.env.REACT_APP_FINMAQ_GO_FILES_URL}/alert-icon.svg`,
    IMAGEN_CONFIRMACION_DE_ENVIO : `${process.env.REACT_APP_FINMAQ_GO_FILES_URL}/confirmacion-de-envio.svg`,
    URL_BASE_API : `${process.env.REACT_APP_URL_BASE_API}`,
    ACCION_EXITOSA : `${process.env.REACT_APP_FINMAQ_GO_FILES_URL}/accion-exitosa.svg`,
    ERROR_AL_GUARDAR : `${process.env.REACT_APP_FINMAQ_GO_FILES_URL}/error-al-guardar.svg`,
    TIEMPO_DE_ESPERA_EXCEDIDO : `${process.env.REACT_APP_FINMAQ_GO_FILES_URL}/tiempo-de-espera-excedido.svg`,
};